@import '~antd/dist/antd.less';
body, html {
    margin: 0;
    box-sizing: border-box;
    background-color: #F3F3F3;
    font-size: 10px;
}

*, *:before, *:after {
    box-sizing: inherit;
}

